import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import {
  MODES,
  usePasswordResetEmail,
  useVerifyCode,
} from "../../services/AuthService";

import Navbar from "../../components/Navbar";
import { ResetPasswordDialog, ErrorDialog } from "./components";
import { DefaultPage } from "../../App";

import { LOGIN_STRINGS } from "../../strings";
import { ROUTES } from "../../routes/routes";
import { isLoading } from "../../utils";

const useQueryParam = (key) =>
  new URLSearchParams(window.location.search).get(key);

const loadingContent = (
  <div
    style={{
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <CircularProgress />
  </div>
);

const ErrorContent = ({ title, description }) => {
  const history = useHistory();
  const onConfirm = () => {
    // use replace instead of push to override original URL
    history.replace(ROUTES.login);
  };
  return (
    <ErrorDialog
      open={true}
      title={title}
      description={description}
      config={{ showCloseButton: false }}
      onConfirm={onConfirm}
    />
  );
};

const ResetContent = ({ mode, oobCode }) => {
  const history = useHistory();
  const email = usePasswordResetEmail({ mode, oobCode });
  const [showError, setShowError] = useState(false);

  // loading
  if (isLoading(email)) return loadingContent;

  const handleError = () => {
    // error from ResetPasswordDialog
    // eg. code expired
    setShowError(true);
  };

  const onClose = () => {
    history.replace(ROUTES.login);
  };

  // show error content if unable to reset
  if (email === null || showError) {
    return (
      <ErrorContent
        title={LOGIN_STRINGS.RESET_PASSWORD_ERROR_TITLE}
        description={LOGIN_STRINGS.RESET_PASSWORD_ERROR_DESCRIPTION}
      />
    );
  }

  // normal content
  return (
    <ResetPasswordDialog
      open={true}
      email={email}
      oobCode={oobCode}
      handleError={handleError}
      onClose={onClose}
    />
  );
};

const VerifyContent = ({ mode, oobCode }) => {
  const verified = useVerifyCode({ mode, oobCode });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!verified) return;
    // verify ok
    enqueueSnackbar(LOGIN_STRINGS.VERIFY_ACCOUNT_CONFIRMATION, {
      variant: "success",
    });
  }, [verified, enqueueSnackbar]);

  // loading
  if (isLoading(verified)) return loadingContent;

  // show error content if unable to verify
  if (!verified) {
    return (
      <ErrorContent
        title={LOGIN_STRINGS.VERIFY_ACCOUNT_ERROR_TITLE}
        description={LOGIN_STRINGS.VERIFY_ACCOUNT_ERROR_DESCRIPTION}
      />
    );
  }

  return <DefaultPage searchKey="continueUrl" />;
};

const Auth = () => {
  const mode = useQueryParam("mode");
  const oobCode = useQueryParam("oobCode");

  // redirect to default page if no params given
  if (!oobCode || !mode) return DefaultPage;

  let content = null;
  switch (mode) {
    case MODES.RESET_PASSWORD:
      content = <ResetContent mode={mode} oobCode={oobCode} />;
      break;
    case MODES.VERIFY_EMAIL:
      content = <VerifyContent mode={mode} oobCode={oobCode} />;
      break;
    default:
      return <DefaultPage searchKey="continueUrl" />;
  }

  return (
    <>
      <Navbar />
      {content}
    </>
  );
};

export default Auth;
