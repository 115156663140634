import React, { useState } from "react";
import {
  Card,
  TextField,
  Button,
  Typography,
  useMediaQuery,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { LOGIN_STRINGS } from "../../strings";
import { isEmailValid } from "../../utils";
import { ROUTES } from "../../routes/routes";
import {
  login,
  signOut,
  createSessionCookie,
  redirectFromAppName,
  useRedirectUrl,
} from "../../services/AuthService";

import { PasswordField, Title, Banner } from "../../components";
import { ForgotPasswordDialog } from "./components";
import Navbar from "../../components/Navbar";

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
`;

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => (props.$mobile ? "100%" : "475px")};
  margin-top: ${(props) => (props.$mobile ? "60px" : "15vh")};
  padding: 1rem;
`;

const MyForm = styled.form`
  width: 100%;
`;

const FormCard = styled(Card)`
  width: 100%;
  padding: 20px;
`;

const FormRow = styled(Box)`
  padding: 1rem;
`;

const LinkCard = styled(Card)`
  padding: 1.25rem;
  width: 100%;
  margin-top: 1.25rem;
`;

const ErrorRow = styled(Box)`
  text-align: left;
  padding-left: 2rem;
  font-size: 0.875rem;
`;

const MyButton = styled(Button)`
  width: 200px;
`;

const Link = styled(Box)`
  ${({ theme }) => `
    font-weight: normal;
    cursor: pointer;
    font-size: 0.875rem;
    display: inline-block;
    color ${theme.palette.text.secondary}
  `}
`;

const LinkTextHighlight = styled.span`
  ${({ theme }) => `
    margin-left: 0.5rem;
    color ${theme.palette.primary.main}
  `}
`;

const LoginBanner = styled(Banner)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const redirectUrl = useRedirectUrl();

  const history = useHistory();

  const onChangeEmail = (event) => {
    setError("");
    setEmail(event.target.value);
  };

  const onBlurEmail = () => {
    isEmailValid(email)
      ? setEmailError("")
      : setEmailError(LOGIN_STRINGS.ERROR_INVALID_EMAIL_FORMAT);
  };

  const onChangePassword = (value) => {
    setError("");
    setPassword(value);
  };

  const onForgotPassword = () => {
    setOpenModal(true);
  };

  const handleError = async (error) => {
    console.error("onLogin", error);
    setError(LOGIN_STRINGS.ERROR_SIGN_IN);
    await signOut();
  };

  const resetErrors = () => {
    setError("");
    setEmailError("");
    setPasswordError("");
  };

  const handleRedirect = () => {
    const continueUrl = new URLSearchParams(window.location.search).get(
      "continue"
    );

    if (continueUrl) {
      window.location.href = continueUrl;
      return;
    }
    // let Home handle redirect
    history.push(ROUTES.home + window.location.search);
  };

  const onLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const userCredential = await login(email, password);
      const user = userCredential.user;
      const idToken = await user.getIdToken();
      const response = await createSessionCookie(idToken);
      console.log("createSessionCookie response", response);
      handleRedirect();
      resetErrors();
    } catch (error) {
      await handleError(error);
    }
    setLoading(false);
  };

  const isFormInvalid = () => password === "" || !isEmailValid(email);

  const title = LOGIN_STRINGS.TITLE_SIGNIN.replace(
    "{app}",
    redirectFromAppName()
  );

  return (
    <>
      <Navbar />
      <Container>
        {redirectUrl && <LoginBanner text={LOGIN_STRINGS.SIGN_IN_BANNER} />}
        <FormContainer $mobile={mobile}>
          <MyForm>
            <FormCard variant="outlined">
              <Title title={title} />
              <FormRow>
                <TextField
                  variant="filled"
                  fullWidth
                  error={emailError === "" ? false : true}
                  helperText={emailError}
                  name="email"
                  label={LOGIN_STRINGS.FORM_EMAIL}
                  type="email"
                  value={email}
                  onChange={onChangeEmail}
                  onBlur={onBlurEmail}
                />
              </FormRow>
              <FormRow>
                <PasswordField
                  error={passwordError}
                  name="password"
                  label={LOGIN_STRINGS.FORM_PASSWORD_SIGNIN}
                  onChange={onChangePassword}
                />
              </FormRow>
              {error !== "" && (
                <ErrorRow>
                  <span>{error}</span>
                </ErrorRow>
              )}
              <FormRow>
                <MyButton
                  type="submit"
                  variant="contained"
                  onClick={onLogin}
                  disabled={isFormInvalid() || loading}
                  color="primary"
                  size="large"
                >
                  {loading ? (
                    <CircularProgress size={28} />
                  ) : (
                    <Typography variant="button">
                      {LOGIN_STRINGS.FORM_SIGNIN}
                    </Typography>
                  )}
                </MyButton>
              </FormRow>
              <Link onClick={onForgotPassword}>
                {LOGIN_STRINGS.FORGOT_PASSWORD}
              </Link>
            </FormCard>
            <LinkCard variant="outlined">
              <Link
                onClick={() =>
                  history.push(ROUTES.signup + window.location.search)
                }
              >
                {LOGIN_STRINGS.LINK_REGISTER}
                <LinkTextHighlight>
                  {LOGIN_STRINGS.LINK_REGISTER_PRIMARY}
                </LinkTextHighlight>
              </Link>
            </LinkCard>
          </MyForm>
          <ForgotPasswordDialog
            mobile={mobile}
            open={openModal}
            onClose={() => setOpenModal(false)}
          ></ForgotPasswordDialog>
        </FormContainer>
      </Container>
    </>
  );
};

export default Login;
