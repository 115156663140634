import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {useSnackbar} from "notistack";

import {
  sendEmailVerification,
  ssoLogout,
  useRedirectUrl,
} from "../services/AuthService";
import {useMobileLayout} from "../services/UiServices";

import Navbar from "../components/Navbar";
import {Banner} from "../components";

import {ROUTES} from "../routes/routes";
import {isLoading} from "../utils";

import {AUTH_STRINGS, GENERAL_STRINGS, HOME_STRINGS} from "../strings";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.$mobile ? "left" : "center")};
  width: 100%;
  height: 100%;
  position: relative;
`;

const StyledTextRow = styled.div`
  margin: 16px;
`;

const StyledLinkSpan = styled.span`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledBanner = styled(Banner)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const maskEmail = (email) =>
  email.substring(0, 1) + "***" + email.substring(email.indexOf("@") - 1);

const VerifyPage = () => {
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const redirectUrl = useRedirectUrl();
  const mobile = useMobileLayout();

  const auth = useSelector((state) => state.firebase.auth);
  const [count, setCount] = useState(0);

  const send = (showToast = true) => {
    console.debug("send", redirectUrl, showToast);
    sendEmailVerification(redirectUrl)
      .then(() => {
        if (showToast)
          enqueueSnackbar(AUTH_STRINGS.VERIFY_EMAIL_SENT, {variant: "success"});
      })
      .catch((err) => {
        if (showToast)
          enqueueSnackbar(GENERAL_STRINGS.MSG_ERROR, {variant: "error"});
        console.warn("onResend", err);
      })
      .finally(() => {
        // setSent(true);
        setCount(60);
      });
  };

  const onResend = () => {
    send(true);
  };

  // run once only
  useEffect(() => {
    // wait until redirectUrl is ready
    if (isLoading(redirectUrl)) return;
    send(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUrl]);

  // countdown
  useEffect(() => {
    if (count <= 0) return;
    const timer = setTimeout(() => {
      setCount(count - 1);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [count]);

  const onLogout = async () => {
    await ssoLogout();
    history.push(ROUTES.login);
  };

  const navbarConfig = {
    overflow: [
      {
        label: HOME_STRINGS.MENU_LOGOUT,
        onClick: onLogout,
      },
    ],
  };

  // console.debug("VerifyPage", auth, redirectUrl);

  const resendContent =
    count > 0 ? (
      <>
        <span>{AUTH_STRINGS.VERIFY_EMAIL_RESEND_COUNTING_1}</span>
        <span style={{fontFamily: "Arial"}}>{count}</span>
        <span>{AUTH_STRINGS.VERIFY_EMAIL_RESEND_COUNTING_2}</span>
      </>
    ) : (
      <>
        <span>{AUTH_STRINGS.VERIFY_EMAIL_RESEND_READY_1}</span>
        <StyledLinkSpan onClick={onResend}>
          {AUTH_STRINGS.VERIFY_EMAIL_RESEND_READY_2}
        </StyledLinkSpan>
      </>
    );

  return (
    <>
      <Navbar config={navbarConfig} />
      <Container $mobile={mobile}>
        {redirectUrl && (
          <StyledBanner text={AUTH_STRINGS.VERIFY_EMAIL_BANNER} />
        )}
        <StyledTextRow>
          <span>
            {AUTH_STRINGS.VERIFY_EMAIL_MESSAGE}
            {maskEmail(auth.email)}
          </span>
        </StyledTextRow>
        <StyledTextRow>
          <span>{AUTH_STRINGS.VERIFY_EMAIL_CHECK_MAILBOX}</span>
        </StyledTextRow>
        <StyledTextRow>{resendContent}</StyledTextRow>
      </Container>
    </>
  );
};

export default VerifyPage;
