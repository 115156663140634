import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";

import {
  ssoLogout, useRedirectUrl
} from "../../services/AuthService";

import { AppCard } from "./components";
import { CREATE_URL, MYPROJECTS_URL } from "../../config/config";
import { ROUTES } from "../../routes/routes";

import icon_create from "../../images/icon_create.png";
import icon_myprojects from "../../images/icon_myprojects.png";
import Navbar from "../../components/Navbar";

import {HOME_STRINGS} from "../../strings";
import { useEffect } from "react";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const AppCards = styled.div`
  width: ${(props) => (props.$mobile ? "100%" : "75%")};
  display: flex;
  flex-direction: ${(props) => props.$mobile && "column"};
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 100%;
  max-width: 1500px;
`;

const APPS = [
  {
    title: HOME_STRINGS.APP_MYPROJECTS,
    url: MYPROJECTS_URL,
    image: icon_myprojects,
  },
  {
    title: HOME_STRINGS.APP_CREATE,
    url: CREATE_URL,
    image: icon_create,
  },
];

const Home = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const redirectUrl = useRedirectUrl();

  // handle redirect
  useEffect(() => {
    if (redirectUrl) window.location.href = redirectUrl;
  }, [redirectUrl]);

  // not rendering anything if redirect
  if (redirectUrl) return <></>;

  const handleLogout = async () => {
    await ssoLogout();
    history.push(ROUTES.login);
  };

  const navbarConfig = {
    overflow: [
      {
        label: HOME_STRINGS.MENU_LOGOUT,
        onClick: handleLogout,
      }
    ]
  }

  return (
    <>
      <Navbar config={navbarConfig}/>
      <Container>
        <AppCards $mobile={mobile}>
          {APPS.map((app, index) => (
            <AppCard
              key={index}
              title={app.title}
              url={app.url}
              image={app.image}
            />
          ))}
        </AppCards>
      </Container>
    </>
  );
};

export default Home;
