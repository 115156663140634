import React, { useState } from "react";
import {
  Card,
  TextField,
  Button,
  Typography,
  useMediaQuery,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { useSnackbar } from "notistack";

import { LOGIN_STRINGS } from "../../strings";
import { Title, Banner, PasswordField } from "../../components";
import { isEmailValid } from "../../utils";
import {
  register,
  signOut,
  createSessionCookie,
  redirectFromAppName,
  useRedirectUrl,
} from "../../services/AuthService";
import { createProfile } from "../../services/ApiService";
import { ROUTES } from "../../routes/routes";
import Navbar from "../../components/Navbar";

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
`;

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => (props.$mobile ? "100%" : "475px")};
  margin-top: ${(props) => (props.$mobile ? "60px" : "15vh")};
  padding: 1rem;
`;

const MyForm = styled.form`
  width: 100%;
`;

const FormCard = styled(Card)`
  width: 100%;
  padding: 20px;
`;

const FormRow = styled(Box)`
  padding: 1rem;
`;

const LinkCard = styled(Card)`
  padding: 1.25rem;
  width: 100%;
  margin-top: 1.25rem;
`;

const ErrorRow = styled(Box)`
  text-align: left;
  padding-left: 2rem;
  font-size: 0.875rem;
`;

const MyButton = styled(Button)`
  width: 200px;
`;

const Link = styled(Box)`
  ${({ theme }) => `
    font-weight: normal;
    cursor: pointer;
    font-size: 0.875rem;
    display: inline-block;
    color ${theme.palette.text.secondary}
  `}
`;

const LinkTextHighlight = styled.span`
  ${({ theme }) => `
    margin-left: 0.5rem;
    color ${theme.palette.primary.main}
  `}
`;

const LoginBanner = styled(Banner)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const getErrorMessage = (code, defaultMessage = LOGIN_STRINGS.ERROR_SIGN_UP) =>
  code && code in LOGIN_STRINGS.ERROR_REGISTER_TRANSACTION
    ? LOGIN_STRINGS.ERROR_REGISTER_TRANSACTION[code]
    : defaultMessage;

const SignUp = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullname, setFullname] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const redirectUrl = useRedirectUrl();

  const history = useHistory();

  const onChangeEmail = (event) => {
    setError("");
    setEmail(event.target.value);
  };

  const onBlurEmail = () => {
    isEmailValid(email)
      ? setEmailError("")
      : setEmailError(LOGIN_STRINGS.ERROR_INVALID_EMAIL_FORMAT);
  };

  const onBlurPassword = () => {
    isPasswordValid(password)
      ? setPasswordError("")
      : setPasswordError(LOGIN_STRINGS.ERROR_WEAK_PASSWORD);
  };

  const onChangePassword = (value) => {
    setError("");
    setPassword(value);
  };

  const isPasswordValid = (password) => {
    if (password === "") {
      return false;
    }
    if (password.length < 6) {
      return false;
    } else {
      return true;
    }
  };

  const onChangeFullname = (event) => {
    setError("");
    setFullname(event.target.value);
  };

  const handleError = async (error) => {
    console.error("onRegister", error);
    setError(getErrorMessage(error?.code));
    await signOut();
  };

  const resetError = () => {
    setError("");
    setEmailError("");
    setPasswordError("");
  };

  const handleRedirect = async () => {
    if (!redirectUrl) {
      return history.push(ROUTES.home);
    }
    window.location.href = redirectUrl;
  };

  const onRegister = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const user = await register(email, password, fullname, redirectUrl);
      await createProfile(email, fullname);
      const idToken = await user.getIdToken();
      const response = await createSessionCookie(idToken);
      enqueueSnackbar(LOGIN_STRINGS.ACCOUNT_CREATED, {
        variant: "success",
      });
      console.log("createSessionCookie response", response);
      handleRedirect();
      resetError();
    } catch (error) {
      await handleError(error);
    }

    setLoading(false);
  };

  const isFormInvalid = () => {
    return (
      password === "" ||
      fullname === "" ||
      !isEmailValid(email) ||
      !isPasswordValid(password)
    );
  };

  const title = LOGIN_STRINGS.TITLE_REGISTER.replace(
    "{app}",
    redirectFromAppName()
  );

  return (
    <>
      <Navbar />
      <Container>
        {redirectUrl && <LoginBanner text={LOGIN_STRINGS.SIGN_IN_BANNER} />}
        <FormContainer $mobile={mobile}>
          <MyForm>
            <FormCard variant="outlined">
              <Title title={title} />
              <FormRow>
                <TextField
                  fullWidth
                  variant="filled"
                  name="fullname"
                  label={LOGIN_STRINGS.FORM_NAME}
                  value={fullname}
                  onChange={onChangeFullname}
                />
              </FormRow>
              <FormRow>
                <TextField
                  fullWidth
                  variant="filled"
                  error={emailError === "" ? false : true}
                  helperText={emailError}
                  name="email"
                  label={LOGIN_STRINGS.FORM_EMAIL}
                  type="email"
                  value={email}
                  onChange={onChangeEmail}
                  onBlur={onBlurEmail}
                />
              </FormRow>
              <FormRow>
                <PasswordField
                  error={passwordError}
                  name="password"
                  label={LOGIN_STRINGS.FORM_PASSWORD_SIGNIN}
                  onChange={onChangePassword}
                  onBlur={onBlurPassword}
                />
              </FormRow>
              {error !== "" && (
                <ErrorRow>
                  <span>{error}</span>
                </ErrorRow>
              )}
              <FormRow>
                <MyButton
                  type="submit"
                  variant="contained"
                  onClick={onRegister}
                  disabled={isFormInvalid() || loading}
                  color="primary"
                  size="large"
                >
                  {loading ? (
                    <CircularProgress size={28} />
                  ) : (
                    <Typography variant="button">
                      {LOGIN_STRINGS.FORM_REGISTER}
                    </Typography>
                  )}
                </MyButton>
              </FormRow>
            </FormCard>
            <LinkCard variant="outlined">
              <Link
                onClick={() =>
                  history.push(ROUTES.login + window.location.search)
                }
              >
                {LOGIN_STRINGS.LINK_SIGNIN}
                <LinkTextHighlight>
                  {LOGIN_STRINGS.LINK_SIGNIN_PRIMARY}
                </LinkTextHighlight>
              </Link>
            </LinkCard>
          </MyForm>
        </FormContainer>
      </Container>
    </>
  );
};

export default SignUp;
