const emailRegExPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmailValid = (email) =>
  email && email.match(emailRegExPattern) ? true : false;

export const getCookieByKey = (key) => {
  let name = key + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const isLoading = (obj) => typeof obj === "undefined";

export const getApiBase = (base) =>
  window.location.hostname === "localhost"
    ? process.env.REACT_APP_LOCAL_API // localhost will use proxy, refer to setupProxy.js
      ? base.LOCAL // localhost + LOCAL_API = use local cloud functions
      : process.env.REACT_APP_DEMO_MODE
      ? base.TESTING // localhost + DEMO_MODE = use testing cloud functions
      : base.LIVE // localhost + no DEMO_MODE = use live cloud functions
    : process.env.REACT_APP_DEMO_MODE
    ? base.TESTING // not localhost
    : base.LIVE;
